$brand-color: #f74f9e;
@import "minima";

/**
 * Links
 *
 * Why? because I wanted the hover state, damnit.
 *
 */
 a {
  color: $brand-color;
  text-decoration: none;

  &:visited {
    color: darken($brand-color, 15%);
  }

  &:hover {
    color: darken($brand-color, 20%);
    text-decoration: underline;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}

.my-face {
  display: block;
  border-radius: 50%;
  margin: auto;
  width: 400px;
  aspect-ratio: 1;
}

.project-description {
  margin-bottom: 1rem;
}

.project-image {
  margin: 0 auto;
  width: 100%;
  height: 350px;
  max-height: 300px;
  overflow: hidden;
}

@media (min-width: 48em) {
  .project-row {
    display: flex;
  }

  .project-description {
    width: 66%;
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .project-image {
    width: 33%;
    max-height: none;
    height: auto;
  }
}